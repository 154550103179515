import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Hero from '../../components/Hero'
import Article, { Text, Title, List } from '../../components/Article'

import ContactCard from '../../components/ContactCard'
import Section from '../../components/Section'

import Button from '../../components/Button'
// import Link from '../../components/Link'

export default function Images ({ data: { hero }, ...props }) {
  return (
    <>
      <Hero {...hero} light title="Images" />

      <Article>
        <Text>
          Our photography inspires our audience of partners and customers, local
          and international. It builds on how we care about getting goods from A
          to B, and the boost of energy we get from helping our customers and
          partners.
        </Text>
        <Text>
          We are proud of the nature of our work – both at the office and at
          sea. Individuals and the team in NCL have a positive, exciting and
          optimistic energy.
        </Text>
        <Title level="2">Usage</Title>

        <Text>
          We use our own photos were possible. The photos we use are simple,
          clear, energetic convey a clear message in accordance with our main
          company goals.
        </Text>

        <List
          items={[
            'Keep it free of disorganized and complicated elements',
            'Use simple compositions',
            'Balance photography with brand elements – 2 % yellow',
            'Decide if you are going for super close up and details or overview shots',
            'Help us to convey the poetic beauty of the nature of logistics ',
          ]}
        />

        <Button
          primary
          to="https://res.cloudinary.com/drbllde7m/raw/upload/v1/ievv_filestore/e65abcf8a37e47349620a9ab67c806b035e709b7babe436a83bc24ddf833a839.docx"
        >
          Download the complete photo brief
        </Button>
      </Article>

      <Section>
        <Title>Any questions?</Title>
        <ContactCard who="ada-jakobsen" />
      </Section>
    </>
  )
}

Images.propTypes = {
  data: PropTypes.object.isRequired,
}

Images.defaultProps = {
  brandbook: true,
}

export const query = graphql`
  query {
    hero: imageSharp(fluid: { originalName: { regex: "/bridge2/" } }) {
      fluid(maxWidth: 1400) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`
